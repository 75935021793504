import {
    DESCARGA_SUC_EXITO,
    DESCARGA_SUC_ERROR,
    COMENZAR_DESCARGA_SUC,
} from '../types';


const initialsatate ={
    descargaSUC: [],
    error: null,
    loading: false
}

export default function(state = initialsatate, action){
    switch(action.type) {
                    case COMENZAR_DESCARGA_SUC:
                        return{
                            ...state,
                            loading: true,
                            
                        }
                        case DESCARGA_SUC_EXITO:
                            return{
                                ...state,
                                loading: false,
                                error: false,
                                descargaSUC: action.payload
                            }
                            case DESCARGA_SUC_ERROR:
                                return{
                                    ...state,
                                    loading: false,
                                    error: action.payload
                                }

        default:
            return state;
    }
}
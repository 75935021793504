import {
    AGREGAR_NUEVOPROBLEMA,
    AGREGAR_NUEVOPROBLEMA_EXITO,
    AGREGAR_NUEVOPROBLEMA_ERROR
} from '../types';


const initialsatate ={
    nuevoproblema: [],
    error: null,
    loading: false
}

export default function(state = initialsatate, action){
    switch(action.type) {

        case AGREGAR_NUEVOPROBLEMA:
            return{
                ...state,
                loading: true
            }
            case AGREGAR_NUEVOPROBLEMA_EXITO:
                return{
                    ...state,
                    loading: false,
                    nuevoproblema: [...state.nuevoproblema, action.payload]
                }
                case AGREGAR_NUEVOPROBLEMA_ERROR:
                    return{
                        ...state,
                        loading: false,
                        error: action.payload
                    }
        default:
            return state;
    }
}
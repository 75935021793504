import {combineReducers} from 'redux';
//LOGIN
import loginReducer from './loginReducer';
import descargaticket from './descargaweb';
import descargasuc from './descargasuc';
import descargauser from './descargausuarios';
import descargareporte from './descargareporte';
import descargareportepormes from './descargaticketpormes';
import descargaproblema from './descargaproblema';
import consultareportemes from './consultareportemes';
// DESCARGAS DE TABLAS

//AÑADIR NUEVO 
import nuevoticket from './nuevoticket'
import nuevoproblema from './nuevoproblema'
//FOLIOS

//REDUCESRS

export default combineReducers({
     Login: loginReducer,
     descargaticket: descargaticket,
     nuevoticket: nuevoticket,
     descargasuc: descargasuc,
     descargauser: descargauser,
     descargareporte: descargareporte,
     descargareportepormes: descargareportepormes,
     descargaproblema: descargaproblema,
     nuevoproblema: nuevoproblema,
     consultareportemes: consultareportemes,
});
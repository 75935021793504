// GUARDAD EMISOR
export const AGREGAR_TICKET = 'AGREGAR_TICKET';
export const AGREGAR_TICKET_EXITO = 'AGREGAR_TICKET_EXITO';
export const AGREGAR_TICKET_ERROR = 'AGREGAR_TICKET_ERROR';

// EDITAR
export const TICKET_EDITAR_EXITO = 'TICKET_EDITAR_EXITO'
export const TICKET_EDITAR_ERROR = 'TICKET_EDITAR_ERROR'
export const EDITAR_TICKET = 'EDITAR_TICKET'

// MOSTRAR TICKET
export const COMENZAR_DESCARGA_EMISOR = 'COMENZAR_DESCARGA_EMISOR';
export const DESCARGA_EXITO = 'DESCARGA_EXITO';
export const DESCARGA_ERROR = 'DESCARGA_ERROR';



export const DESCARGA_USUARIOPKT_EXITO = 'DESCARGA_USUARIOPKT_EXITO';
export const DESCARGA_USUARIOPKT_ERROR = 'DESCARGA_USUARIOPKT_ERROR';
export const COMENZAR_DESCARGA_USUARIOPKT = 'COMENZAR_DESCARGA_USUARIOPKT';

//USUARIO
export const USUARIO_AUTENTICADO = 'USUARIO_AUTENTICADO';

// LOGIN AUTENTICACION
export const ADD_FLASH_MESSAGE = 'ADD_FLASH_MESSAGE';
export const DELETE_FLASH_MESSAGE = 'DELETE_FLASH_MESSAGE';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

// DIRECCIONES
export const AGREGAR_DIRECIONEMISOR = 'AGREGAR_DIRECIONEMISOR';
export const AGREGAR_DIRECIONEMISOR_EXITO = 'AGREGAR_DIRECIONEMISOR_EXITO';
export const AGREGAR_DIRECIONEMISOR_ERROR = 'AGREGAR_DIRECIONEMISOR_ERROR';
export const COMENZAR_DESCARGA_DIRECIONEMISOR = 'COMENZAR_DESCARGA_DIRECIONEMISOR';
export const DESCARGA_DIRECION_EXITO = 'DESCARGA_DIRECION_EXITO';
export const DESCARGA_DIRECCION_ERROR = 'DESCARGA_DIRECION_EXITO';

// CLIENTES
export const AGREGAR_CLIENTES = 'AGREGAR_CLIENTES';
export const AGREGAR_CLIENTES_EXITO = 'AGREGAR_CLIENTES_EXITO';
export const AGREGAR_CLIENTES_ERROR = 'AGREGAR_CLIENTES_ERROR';
export const COMENZAR_DESCARGA_CLIENTES = 'COMENZAR_DESCARGA_CLIENTES';
export const DESCARGA_CLIENTES_EXITO = 'DESCARGA_CLIENTES_EXITO';
export const DESCARGA_CLIENTES_ERROR = 'DESCARGA_CLIENTES_ERROR';
export const DESCARGA_DIRECIONCLIENTE_EXITO = 'DESCARGA_DIRECIONCLIENTE_EXITO';
export const DESCARGA_DIRECCIONCLIENTE_ERROR = 'DESCARGA_DIRECCIONCLIENTE_ERROR';
export const COMENZAR_DESCARGA_DIRECIONCLIENTE = 'COMENZAR_DESCARGA_DIRECIONCLIENTE';
export const AGREGAR_DIRECCIONCLIENTE = 'AGREGAR_DIRECCIONCLIENTE';
export const AGREGAR_DIRECCIONCLIENTE_EXITO = 'AGREGAR_DIRECCIONCLIENTE_EXITO';
export const AGREGAR_DIRECCIONCLIENTE_ERROR = 'AGREGAR_DIRECCIONCLIENTE_ERROR';

//FOLIOS

export const AGREGAR_FOLIOS = 'AGREGAR_CLIENTES';
export const AGREGAR_FOLIOS_EXITO = 'AGREGAR_DIRECCIONCLIENTE_EXITO';
export const AGREGAR_FOLIOS_ERROR = 'AGREGAR_DIRECCIONCLIENTE_ERROR';
export const DESCARGA_FOLIOS_EXITO = 'DESCARGA_FOLIOS_EXITO';
export const DESCARGA_FOLIOS_ERROR = 'DESCARGA_FOLIOS_ERROR';
export const COMENZAR_DESCARGA_FOLIOS = 'COMENZAR_DESCARGA_FOLIOS';

export const DESCARGA_WEB_EXITO = 'DESCARGA_WEB_EXITO';
export const DESCARGA_WEB_ERROR  = 'DESCARGA_WEB_ERROR';
export const COMENZAR_DESCARGA_WEB  = 'COMENZAR_DESCARGA_WEB';


export const DESCARGA_SUC_EXITO = 'DESCARGA_SUC_EXITO';
export const DESCARGA_SUC_ERROR  = 'DESCARGA_SUC_ERROR';
export const COMENZAR_DESCARGA_SUC  = 'COMENZAR_DESCARGA_SUC';


export const DESCARGA_REPORTE_EXITO =  'DESCARGA_REPORTE_EXITO';
export const DESCARGA_REPORTE_ERROR = 'DESCARGA_REPORTE_ERROR';
export const COMENZAR_DESCARGA_REPORTE = 'COMENZAR_DESCARGA_REPORTE';

export const DESCARGA_PROBLEMA_EXITO = 'DESCARGA_PROBLEMA_EXITO';
export const DESCARGA_PROBLEMA_ERROR = 'DESCARGA_PROBLEMA_ERROR';
export const COMENZAR_DESCARGA_PROBLEMA = 'COMENZAR_DESCARGA_PROBLEMA';


export const AGREGAR_NUEVOPROBLEMA = 'AGREGAR_NUEVOPROBLEMA';
export const AGREGAR_NUEVOPROBLEMA_EXITO = 'AGREGAR_NUEVOPROBLEMA_EXITO';
export const AGREGAR_NUEVOPROBLEMA_ERROR = 'AGREGAR_NUEVOPROBLEMA_ERROR';

export const CONSULTA_DATOPORMES = 'CONSULTA_DATOPORMES';
export const CONSULTA_DATOPORMES_EXITO = 'CONSULTA_DATOPORMES';
export const CONSULTA_DATOPORMES_ERROR = 'CONSULTA_DATOPORMES';


// LOGIN
export const authLogin = 'authLogin';
export const authCheckingFinish = 'authCheckingFinish';
export const authLogout = 'authLogout';

// DASBOARD
export const DESCARGA_TICKETSPORMES_EXITO = "DESCARGA_TICKETSPORMES_EXITO";
export const DESCARGA_TICKETSPORMES_ERROR = "DESCARGA_TICKETSPORMES_ERROR";
export const COMENZAR_TICKETSPORMES_REPORTE = "COMENZAR_TICKETSPORMES_REPORTE";
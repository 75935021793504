import {
    DESCARGA_USUARIOPKT_EXITO,
    DESCARGA_USUARIOPKT_ERROR,
    COMENZAR_DESCARGA_USUARIOPKT,
} from '../types';


const initialsatate ={
    descargaUser: [],
    error: null,
    loading: false
}

export default function(state = initialsatate, action){
    switch(action.type) {
                    case COMENZAR_DESCARGA_USUARIOPKT:
                        return{
                            ...state,
                            loading: true,
                            
                        }
                        case DESCARGA_USUARIOPKT_EXITO:
                            return{
                                ...state,
                                loading: false,
                                error: false,
                                descargaUser: action.payload
                            }
                            case DESCARGA_USUARIOPKT_ERROR:
                                return{
                                    ...state,
                                    loading: false,
                                    error: action.payload
                                }

        default:
            return state;
    }
}
import {
  USUARIO_AUTENTICADO,
  SET_CURRENT_USER,
}
 from '../types';
 import isEmpty from 'lodash/isEmpty';

 const initialState={
   autenticado:[],
   error: null,
   loading: false,
   isAuthenticated: false,
   user: {}
 }

 export default function (state = initialState, action){
   switch(action.type){
     case USUARIO_AUTENTICADO:
       return{
         ...state,
         loading: true,
         user: action.user,
         isAuthenticated: true
       }
       case SET_CURRENT_USER:
      return {
        isAuthenticated: !isEmpty(action.user),
        user: action.user
      };
     default:
       return state;
   }
 }

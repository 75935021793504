import {
    DESCARGA_REPORTE_EXITO,
    DESCARGA_REPORTE_ERROR,
    COMENZAR_DESCARGA_REPORTE,
} from '../types';


const initialsatate ={
    descargaReporte: [],
    error: null,
    loading: false
}

export default function(state = initialsatate, action){
    switch(action.type) {
                    case COMENZAR_DESCARGA_REPORTE:
                        return{
                            ...state,
                            loading: true,
                            
                        }
                        case DESCARGA_REPORTE_EXITO:
                            return{
                                ...state,
                                loading: false,
                                error: false,
                                descargaReporte: action.payload
                            }
                            case DESCARGA_REPORTE_ERROR:
                                return{
                                    ...state,
                                    loading: false,
                                    error: action.payload
                                }

        default:
            return state;
    }
}
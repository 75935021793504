import {
    USUARIO_AUTENTICADO,
  }
   from '../types';
   import clienteAxios from '../config/axios';
   import setAuthorizationToken from '../utils/setAuthorizationToken';
   import jwtDecode from 'jwt-decode';
   import { SET_CURRENT_USER } from '../types/index';
   import Swal from 'sweetalert2';
   

   export function setCurrentUser(user) {
    return {
      type: SET_CURRENT_USER,
      user
    };
  }

   export function UsuarioAutenticado(userdata) {
        return async (dispatch) =>{
      
          try {
            //insertar en api
            const repuesta = await  clienteAxios.post('/Api/Login/ObtenerLogin?Origin=Monitoreo', {username: userdata.txtUser,password: userdata.txtPass}
            ,
            {    
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Credentials': 'true',
            }
            )
            .then(res => res.data)
            .then(data => {
                const token = data.token;
                localStorage.setItem('jwtToken', token);
                dispatch(setCurrentUser(jwtDecode(token)));
                window.location = '/';           
            })
        } catch (error) {
            console.log(error)
            //  dispatch (agregaremisorerror(true));
            Swal.fire(
                'Error',
                'Usuario y contraseña incorrectas' ,
                'error'
             )
        }

        



        //     clienteAxios.post('/Login_api/index.php/api/login', {username: userdata.username,password: userdata.password}
        //     ,
        //     {    
        //         'Content-Type': 'application/json',
        //         'Accept': 'application/json',
        //         'Access-Control-Allow-Origin': 'http://localhost:3000',
        //         'Access-Control-Allow-Credentials': 'true',
        //     }
        //     )
        //     .then(res => res.data)
        //     .then(data => {
        //         const token = data.token;
        //         localStorage.setItem('jwtToken', token);
        //         dispatch(setCurrentUser(jwtDecode(token)));
        //         window.location = '/';
               
        //     })
        //     .catch(err => console.log(err));
         }
   }
   export function logout() {
    return dispatch => {
      localStorage.removeItem('jwtToken');
      setAuthorizationToken(false);
      dispatch(setCurrentUser({}));
      window.location = '/login';
    }
  }
   const ObtenerUsuarioAutenticado = () => ({
        type: USUARIO_AUTENTICADO,
    
   });
import {
    CONSULTA_DATOPORMES,
    CONSULTA_DATOPORMES_EXITO,
    CONSULTA_DATOPORMES_ERROR
} from '../types';


const initialsatate ={
    consultareportemes: [],
    error: null,
    loading: false
}

export default function(state = initialsatate, action){
    switch(action.type) {

        case CONSULTA_DATOPORMES:
            return{
                ...state,
                loading: true
            }
            case CONSULTA_DATOPORMES_EXITO:
                return{
                    ...state,
                    loading: false,
                    consultareportemes: [...state.consultareportemes, action.payload]
                }
                case CONSULTA_DATOPORMES_ERROR:
                    return{
                        ...state,
                        loading: false,
                        error: action.payload
                    }
        default:
            return state;
    }
}
import React, { Component, useContext } from 'react';
import { HashRouter, Route, Switch, Router } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import {PrivateRoute} from './PrivateRoute';
import './App.scss';
import { Provider, useSelector } from 'react-redux';
import store from './store';


// TOKEN
import setAuthorizationToken from './utils/setAuthorizationToken';
import jwtDecode from 'jwt-decode';
import { setCurrentUser } from './actions/loginaction';
if (localStorage.jwtToken) {
  setAuthorizationToken(localStorage.jwtToken);
  store.dispatch(setCurrentUser(jwtDecode(localStorage.jwtToken)));
}
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;
// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));


const Approuter = () =>{
    
    const usuarioauntenticado = useSelector( state => state.Login);
    const autenticado = usuarioauntenticado.isAuthenticated
    // console.log(autenticado);
    return (
        <HashRouter>
            <React.Suspense fallback={loading()}>
                <Switch>
                <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
                <PrivateRoute 
                    path="/" 
                    component={DefaultLayout}
                    isAuthenticated = {autenticado} 
                />
                </Switch>
            </React.Suspense>
      </HashRouter>
    );
  
}

export default Approuter;
